import React from 'react'
import { Location, WindowLocation } from '@reach/router'
import { Flex, Box } from '@rebass/grid/emotion'

import { graphql, StaticQuery, Link } from "gatsby"
import styled from '@emotion/styled'
import Page from '../components/Page'
import { Image } from '../components/Image'
import css, { SerializedStyles } from '@emotion/css'
import { useSwipeable } from 'react-swipeable'

const StyledImg = styled(Image)`
  display: block;
  display: flex;
  width:100%;
  object-fit: contain;
  height:100%;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

const iconPolylineHover = css`
  polyline {
    transition: all 250ms ease-in-out;
  }
  &:hover polyline {
    stroke-width: 4px;
    transition: all 250ms ease-in-out;
  }
`

const iconLineHover = css`
  line {
    transition: all 250ms ease-in-out;
  }
  &:hover line {
    stroke-width: 3px;
    transition: all 250ms ease-in-out;
  }
`

const LeftArrow = () =>
  <svg
    css={iconPolylineHover}
    viewBox="0 0 50 80"
    preserveAspectRatio="xMidYMin slice"
  >
    <polyline
      fill="none"
      stroke="#000"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      points="45.63,75.8 0.375,38.087 45.63,0.375 " />
  </svg>

const RightArrow = () =>
  <svg viewBox="0 0 50 80"
    preserveAspectRatio="xMidYMin slice"
    css={iconPolylineHover}>
    <polyline
      fill="none"
      stroke="#000"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      points="0.375,0.375 45.63,38.087 0.375,75.8 " />
  </svg>

const CloseIcon = () =>
  <svg
    viewBox="0 0 12 12"
    preserveAspectRatio="xMidYMin slice"
    css={iconLineHover}
  >
    <line x1="1" y1="11"
      x2="11" y2="1"
      stroke="black"
      strokeWidth="1" />
    <line x1="1" y1="1"
      x2="11" y2="11"
      stroke="black"
      strokeWidth="1" />
  </svg>

const iconCSS = css`
  cursor: pointer;
`

const CloseButton = ({ className }) =>
  <span
    className={className}
    css={css`
      ${iconCSS};
    `}
  ><CloseIcon />
  </span>

const Next = () =>
  <span
    css={css`
      ${iconCSS}
    `}
  ><RightArrow />
  </span>

const Prev = () =>
  <span
    css={css`
      ${iconCSS};
    `}
  ><LeftArrow />
  </span>

export const getAllPictures = graphql`
  query {
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 2048) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
// /full-preview/id/
const getHashFromLocation = (location?: WindowLocation) =>
  location
    ? location.pathname.split('/')[2]
    : ''

const urlToIdx = (images, currentImage) =>
  images.edges.findIndex(d => d.node.name === getRelPath(currentImage))

const idxToUrl = (images, idx) => images.edges[idx]

const getSizes = (img) => img.node.childImageSharp.sizes

const getRelPath = (img) => img.node.name

const nextIndex = (images, index) => (index + 1) % images.edges.length
const prevIndex = (images, currIndex) =>
  currIndex === 0
    ? images.edges.length - 1
    : currIndex - 1

const navigationIconCSS = css`
  min-width: 15px;
  max-width: 25px;
`
const NavigationIcon =
  ({ css, children, to }: { css?: SerializedStyles, to: string, children: JSX.Element }) =>
    <Box
      css={[navigationIconCSS, css]}
      mr={2}
      ml={2}
      width={[1 / 30, 1 / 40]}
    >
      <Link to={to}>
        {children}
      </Link>
    </Box>

// TODO make 
const RenderList = ({ images, location, navigate, ...props }) => {
  const relPath = getHashFromLocation(location)
  let selectedImage = images.edges.find(d => d.node.name === relPath)
  if (!selectedImage) selectedImage = images.edges[0]

  const nextURL = getRelPath(idxToUrl(images, nextIndex(images, urlToIdx(images, selectedImage))))
  const prevURL = getRelPath(idxToUrl(images, prevIndex(images, urlToIdx(images, selectedImage))))

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => navigate(prevURL),
    onSwipedRight: (eventData) => navigate(nextURL),
    onSwipedDown: (eventData) => navigate('/'),
    delta: 100,                             // min distance(px) before a swipe starts
  })

  return <Flex
    {...handlers}
    alignItems="center"
    justifyContent="center"
    css={css`
      position: relative;
    `}
  >
    <Link to="/">
      <div css={css`
        position: absolute;
        width: 50px;
        height: 50px;
        right: 0;
        top: 0;
      `}>
        <Box
          width={[1 / 30, 1 / 40]}
          className="closeButton"
          css={[css`
          position: absolute;
          right: 5px;
          top: 5px;
          background: #FFF;
          border-radius: 4px;
          max-width: 20px;
          max-height: 20px;
          min-width: 10px;
          min-height: 10px;
          transition: all 250ms ease-in-out;
        `, iconCSS]}
        >
          <CloseButton
            css={css`
            width: 100%;
            height: 100%;
          `}
          />
        </Box>
      </div>
    </Link>
    <NavigationIcon
      to={`/full-preview/${prevURL}`}>
      <Prev />
    </NavigationIcon>
    <Box css={css`
      position: relative;
      width: 100vh;
      height: 100vh;
    `}>
      <StyledImg
        sizes={getSizes(selectedImage)}
        fadeIn
        className="image"
        objFit="contain"
      />
    </Box>
    <NavigationIcon to={`/full-preview/${nextURL}`}>
      <Next />
    </NavigationIcon>
  </Flex >
}

export default (props) => {
  return <Page>
    <Location>{(locationProps) =>
      <StaticQuery
        query={getAllPictures}
        render={(props) =>
          <RenderList
            {...props}
            {...locationProps}
          />}
      />
    }</Location>
  </Page>
}
