import * as React from 'react'
import styled from '@emotion/styled'

import { IconContext } from "react-icons"
import { theme } from '../styles/variables'
import { ThemeProvider } from 'emotion-theming'

const StyledPage = styled.div`
  display: block;
  flex: 1;
  position: relative;
`

interface PageProps {
  className?: string
}

const Page: React.SFC<PageProps> = ({ children, className }) =>
  <ThemeProvider theme={theme}>
    <IconContext.Provider value={{ color: "white" }}>
      <StyledPage className={className}>{children}</StyledPage>
    </IconContext.Provider>
  </ThemeProvider>

export default Page
